<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :single-select="false"
      item-key="clave"
      class="styled-table m-4 mt-2"
      :sort-desc="[true, true]"
      :search="search"
      multi-sort
    >
      <template v-slot:body="{ items }">
        <tbody>
          <template v-for="(ticket, index) in items">
            <tr
              :key="'ReportPayment-' + index"
              @click="clickrow(ticket.id)"
              class="pointman"
              style="color: black"
              :style="{ 'background-color': backgrondRowTable(index), 'border-top':'2px solid #75695f' }"
            >
              <td style="max-width: 10px">
                <v-icon class="ma-2" v-if="ticket.id == expanded">mdi-minus</v-icon>
                <v-icon class="ma-2" v-if="ticket.id != expanded">mdi-plus</v-icon> 
              </td>
              <td class="MyTdPadding" style="width: 250px;">
                <strong>{{ ticket.clave }}</strong>
              </td>
              <td class="MyTdPadding" align="center" style="width: 250px;">
                <strong>{{ ticket.f_solicitud }}</strong>
              </td>
              <td class="MyTdPadding"  align="center" style="width: 90px;">
                <strong>{{ ticket.cantidad }}</strong>
              </td>
              <td class="MyTdPadding" align="center" style="width: 450px;">
                <strong>{{
                  ticket.name + " " + ticket.name2 + " " + ticket.name3
                }}</strong>
              </td>
              <td>
                {{ticket.descript}}
              </td>
            </tr>

            <tr
              :key="'ReportPayment2-' + index"
              v-if="ticket.id == expanded"
              style="background-color: white !important"
            >
              <td colspan="7">
                <v-simple-table
                  style="zoom: 90%"
                  v-for="(sitio, index2) in ticket.sitios"
                  :key="'SubReportPayment2-' + index + '-' + index2"
                >
                  <thead>
                    <tr>
                      <td
                        style="background: white; color: black; padding: 0 !important; padding-top: 20px !important; padding-left: 5px !important; height: 40px; font-size:15px;"
                      >
                        <strong>{{ sitio.nameSitio  }}</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="background-color: rgba(255, 165, 0, 0.4);">
                      <td>
                        <v-simple-table style="zoom: 90%">
                          <thead>
                            <tr
                              style="background: rgb(253 142 48 / 22%); color: black"
                            >
                              <td class="MyTdPadding" align="center">
                                <strong>Tipo de boleto</strong>
                              </td>
                              <td class="MyTdPadding" align="center">
                                <strong>Boleto</strong>
                              </td>
                              <td class="MyTdPadding" align="center">
                                <strong>Cantidad</strong>
                              </td>
                              <td class="MyTdPadding" align="center"  v-if="rol < 2">
                                <strong>Imprimir</strong>
                              </td>
                              <td class="MyTdPadding" align="center">
                                <strong>Conteo de impresiones</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="w-100"
                              v-for="(ticketType, index) in sitio.data"
                              :key="'sitios-' + index"
                            >
                              <td class="MyTdPadding" align="center">
                                <strong>{{ ticketType.id_ticket }}</strong>
                              </td>
                              <td class="MyTdPadding" width="35%">
                                <strong>{{ ticketType.descripcion }} </strong> 
                                <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
                              </td>
                              <td class="MyTdPadding" align="center">
                                <strong>{{ ticketType.cantidad }}</strong>
                              </td>
                              <td class="MyTdPadding" align="center" width="40%"  v-if="rol < 2">
                                <v-btn @click="print(ticketType.id_ticket, ticket.id, ticket.clave)" color="primary">
                                  <i class="bx bxs-file m-2" style="font-size: 20px;"></i>pdf
                                </v-btn>
                              </td>
                              <td class="MyTdPadding pt-2" align="center">
                                <v-badge
                                  :content="ticketType.printed"
                                  :value="ticketType.printed"
                                  color="orange"
                                  overlap
                                >
                                  <v-icon style="font-size: 30px" v-if="ticketType.printed">
                                    mdi-printer
                                  </v-icon>
                                </v-badge>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </td>
                    </tr>                  
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";

export default {
  components: {
  },
  data() {
    return {
      backgrondRowTable,
      flag:         false,
      expanded:     "",
      nowDate: '',
      id: '',
      clave: '',
      dialog: false,
      rol: 0
    };
  },
  props: ["headers", "items", "search"],
  watch:{
    search: function (val) {
      this.expanded = ""
    }
  },
  mounted() {
    setColorHeadFooter();
    this.nowDate = this.formatDate(new Date());
    this.rol = this.$root.token.subrol
  },
  methods: {
    formatDate: function (d){
      let formatted_date =  ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear();
      return formatted_date;
    },
    clickrow(id) {
      if (this.flag == false) {
        this.expanded = this.expanded == id ? "" : id;
      } else {
        this.flag = false;
      }
    },
    print: function (ticketType, idTrans, clave) {
      this.id = ticketType;
      this.clave = clave;

      this.$session.remove("tipo")
      var tipoX = {ticketType:ticketType, idTrans: idTrans, token:this.$root.token.token, format:2}
      this.$session.set("tipo", tipoX);

      setTimeout(() => {
       let routeData = this.$router.resolve({name: 'PdfGenerator'});
        window.open(routeData.href, '_blank');
      }, 10);
    }
  },
};
</script>

<style scoped>
  .row-boleto{
    display: flex;
    justify-content: space-between;
  }
  .content-pdf{
    margin: 0px;
    display: flex;
    justify-content: center;
    width: 700px;
    flex-wrap: wrap;
  }
  .fill-width-x{
    width: 370px;
    border: 1px solid black;
    padding: 15px;
  }
</style>


