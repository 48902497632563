<template>
  <div id="Catologo">
    <DivLoading v-if="ShowLoading" :textLoading="textLoading" />

    <v-app-bar dark style="z-index: 22">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span class="MyTitleMayo">{{ title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <span
        v-show="ShowCata"
        v-if="totalX"
        style="margin: 10px; font-size: 20px"
      >
        MXN ${{ formatPrice(totalX) }}
      </span>

      <v-btn color="success" v-show="ShowCata" v-if="totalX" @click="AddCar">
        <v-icon left> mdi-plus </v-icon>
        AGREGAR
      </v-btn>

      <v-btn
        class="mr-3"
        color="success"
        v-if="ShowBita"
        @click="openView('Boletos disponibles')"
      >
        <v-icon left>mdi-ticket-confirmation</v-icon>
        BOLETOS DISPONIBLES
      </v-btn>

      <v-btn
        v-if="ShowUsers"
        style="margin: 10px"
        fab
        class="d-flex justify-center align-center"
        dark
        @click="dialog = true"
      >
        <span class="material-icons"> person_add </span>
      </v-btn>

      <v-btn
        fab
        style="margin: 10px"
        @click="openView('Bitacora')"
        v-if="ShowCata"
        class="BtnCar"
      >
        <v-badge color="green" :content="badge" v-if="badge">
          <span class="material-icons IconX"> shopping_cart </span>
        </v-badge>
        <span class="material-icons IconX" v-if="!badge"> shopping_cart </span>
      </v-btn>

      <v-btn fab @click="openView('Catalogo')" v-else>
        <v-icon> mdi-home </v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      dark
      style="z-index: 40"
    >
      <v-list-item
        class="imageLogo px-2"
        style="background: white"
        @click="rol < 1 ? openView('Cuenta') : null"
      >
        <div align="center" class="ImgAvartDiv">
          <!-- <v-img :src="avatar" class="ImgCorp"></v-img> -->
          <img :src="avatar" class="ImgCorp" />
        </div>
        <div
          class="MyAlertNotification"
          align="center"
          v-if="alertLogo(avatar)"
          v-show="rol < 1"
        >
          <small>Haz click para agregar tu foto</small>
        </div>
      </v-list-item>

      <v-list-item link @click="rol < 1 ? openView('Cuenta') : null">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ NameCorp }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ TypeUser }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense style="text-align: left">
        <v-list-item-group>
          <div v-for="(item, i) in items" :key="i">
            <v-list-item v-if="!item.sub.length" @click="openView(item.title)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-group
              v-else
              class="active-dropdown"
              dark
              no-action
              :prepend-icon="item.icon"
            >
              <template slot="activator">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>
              <v-list-item
                dark
                v-for="(subitem, index) in item.sub"
                :key="index"
                @click="openView(subitem.title)"
                style="padding-left: 33px !important"
              >
                <v-list-item-icon>
                  <v-icon>{{ subitem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-card>
      <div class="MyCataloge MyCatalogeActive">
        <bitacora ref="bitacora" v-if="ShowBita" :bitacoraTab="bitacoraTab" />
        <proto
          ref="proto"
          :PlaceX="PlaceX"
          :tikcets="tikcets"
          v-if="ShowCata"
          style="height: calc(100%)"
        />
        <div v-if="ShowCata" dark class="my-3 mx-2 text-white footerXX">
          <table style="width: 100%;">
            <tr><td class="p-2">
<!--               <div>
                <span class="child1"  v-html="textnotification" style="width:100wv">
                </span>
              </div> -->
              <div style="text-align: right;">
                <span class="pointman"  @click="TriggerTerminos()">
                  Terminos y condiciones
                </span>
              </div>
            </td></tr>
          </table>
        </div>
        <regis v-if="ShowResgister" />
        <users v-if="ShowUsers" ref="usuarios" />
        <tickets
          v-if="ShowTicketsDisp"
          ref="tickets"
          :detSitios="ticketsDisp"
          :TicketsGenerated="TicketsGenerated"
        />
      </div>
    </v-card>

    <v-dialog v-model="dialog" max-width="730px" class="scroll-block-x">
      <v-card style="zoom: 90%">
        <v-card-title dark class="text-h5 TempoCardStyle">
          Usuarios
          <v-spacer></v-spacer>
        </v-card-title>

        <FormMayoristas :isAdduserr="true" class="py-8 px-6" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="notification"
      width="500"
      persistent
      v-if="textnotification"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Notificación
        </v-card-title>

        <v-card-text v-html="textnotification" style="font-size: 21px; padding: 20px;">
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="notification = false"
          >
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div class="myAlert" style="top: calc(100vh - 73px - 73px)">
      <v-alert
        color="warning"
        dark
        type="warning"
        border="left"
        shaped
        prominent
        style="padding:10px;"
      >
        <div>
        <span class="child1" >
          Estamos experimentando inconvenientes con el servidor de Norteamérica, se está trabajando para solucionarlo. <br>
          Se podrán experimentar intermitencias en el servicio por el momento.
        </span>
        </div>
      </v-alert>
    </div> -->
    <!-- <div class="myAlert">
      <v-alert
        color="primary"
        dark
        type="info"
        border="left"
        shaped
        prominent
        style="padding: 10px"
      >
        <div>
          <span class="child1" @click="TriggerTerminos()">
            Favor de tomar en consideración la vigencia impresa en su boleto,
            posterior a dicha fecha no podrá ser utilizado. <br />
          </span>
        </div>
      </v-alert>
    </div> -->

    <terminios ref="terminos" />
  </div>
</template>

<script>
import { formatPrice } from "@/utils/NumericTools.js";

import $ from "jquery";
import axios from "axios";

//WebSocket
import { io } from "socket.io-client";
var socket;

import stars from "../components/stars.vue";
import bitacora from "../components/mayoristas/Bicatora.vue";
import proto from "../components/mayoristas/prototipo.vue";
import regis from "../components/mayoristas/Register.vue";
import users from "../components/mayoristas/Users.vue";
import tickets from "../components/mayoristas/Tickets.vue";
import FormMayoristas from "../components/mayoristas/Form-mayoristas.vue";
import DivLoading from "../components/Loading.vue";

import terminios from "../components/loginCards/terminos.vue";

export default {
  components: {
    terminios,
    stars,
    DivLoading,
    bitacora,
    proto,
    regis,
    users,
    tickets,
    FormMayoristas,
  },
  name: "CatalogoMain",
  data() {
    return {
      textnotification: ``,
      notification:true,
      formatPrice,
      textLoading: "Activando Boletos un momento por favor..",
      ShowLoading: false,
      totalx: 0,
      items2: [],
      NameCorp: "",
      TypeUser: "",
      title: "",
      drawer: false,
      badge: 0,
      totalX: 0,
      PlaceX: [],
      tikcets: [],
      dialog: false,
      ShowCata: true,
      ShowBita: false,
      ShowResgister: false,
      ShowUsers: false,
      ShowTicketsDisp: false,
      ticketsDisp: [],
      TicketsGenerated: [],
      bitacoraTab: 0,
      items: [
        // { title: "Cuenta", icon: "mdi-account-circle", sub: [] },
        // { title: "Usuarios", icon: "mdi-account-multiple", sub: [] },
        {
          title: "Compras",
          icon: "mdi-cart-arrow-down",
          sub: [
            {
              title: "Mi Carrito",
              icon: "mdi-cart",
              sub: [],
            },
            {
              title: "Ordenes de Pago",
              icon: "mdi-clipboard-text-clock-outline",
              sub: [],
            },
            {
              title: "Autorizados",
              icon: "mdi-clipboard-check-multiple-outline",
              sub: [],
            },
            {
              title: "Bloqueados",
              icon: "mdi-cancel",
              sub: [],
            },
            {
              title: "Vencidos",
              icon: "mdi-calendar-clock",
              sub: [],
            },
          ],
        },
        {
          title: "Boletos disponibles",
          icon: "mdi-ticket-confirmation",
          sub: [],
        },
        { title: "Salir", icon: "bx bx-log-out", sub: [] },
      ],
      avatar: "",
      rol: 0,
      titlesForCar: [
        "Mi Carrito",
        "Ordenes de Pago",
        "Autorizados",
        "Bloqueados",
        "Vencidos",
      ],
    };
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.$children[2].$children[0].$children[0].$children[0].clearForm();
      }
    },
  },
  created() {
    var vm = this;
    vm.$root.socket = io(vm.$root.mySock + vm.$root.myport + "/", {
      transports: ["websocket"],
    });

    vm.$root.socket.connect();

    vm.$root.socket.on("message", (val) => {
      vm.$root.swalAlert(val.icon, val.title, val.text);
    });

    vm.$root.socket.on("tickets", (res) => {
      if (vm.$root.token.idCorp == res.coorId) {
        vm.ticketsDisp = res.data;
      }
    });

    vm.$root.socket.on("TicketsGenerated", (res) => {
      if (vm.$root.token.idCorp == res.coorId) {
        vm.TicketsGenerated = res.data;
      }
    });

    vm.$root.socket.on("ChangeStatus", (res) => {
      if (vm.$refs.bitacora) {
        if (vm.$root.token.idCorp == res.coorId) {
          vm.$refs.bitacora.inibitacora();
        }
      }
    });

    vm.$root.socket.on("precarrito", (res) => {
      if (vm.$refs.bitacora) {
        if (vm.$root.token.idCorp == res.coorId) {
          vm.$refs.bitacora.iniPreCarrito();
        }
      }
    });

    vm.$root.socket.on("responseGeneratedTickets", () => {
      if (vm.$children[3]) {
        try {
          vm.ShowLoading = false;
          vm.$children[3].resolveGenerateTickets();
        } catch (ev) {}
      }
    });

    vm.$root.socket.on("StatusPrintTicket", (res) => {
      vm.textLoading = res.text;
    });

     this.beginMessage()
  },
  mounted() {
    if (this.$cookies.get("user")) {
      this.$root.token = this.$cookies.get("user");
    } else {
      this.$router.push("/");
    }

    //solo mayoristas
    if (this.$root.token.rol != 4) {
      this.$router.push("/");
    }

    $("html").css({ overflow: "hidden" });

    this.iniciar();
    this.openView("Catalogo");
    this.iniPreCarrito();

    this.avatar = this.$root.token.avatar;
    this.rol = this.$root.token.subrol;
    this.NameCorp = this.$root.token.corp;
    this.TypeUser = this.$root.token.cargo;

    if (this.rol < 2) {
      this.items.unshift({
        title: "Usuarios",
        icon: "mdi-account-multiple",
        sub: [],
      });
      // this.items.splice(this.items.length - 1, 0, );
    }
    this.items.unshift({ title: "Inicio", icon: "mdi-home", sub: [] });
  },
  methods: {
    TriggerTerminos() {
      this.$refs.terminos.dialog = !this.$refs.terminos.dialog;
    },
    iniPreCarrito() {
      this.$root.post("/wholesaler/GetPreCarrito", {}).then((response) => {
        var info = this.fixinfo(response.data[1]);
        this.items2 = info;

        if (response.data[2]) {
          if (response.data[2][0]) {
            this.totalx = response.data[2][0].total;
          } else {
            this.totalx = 0;
          }
        } else {
          this.totalx = 0;
        }
      });
    },
    socketX(name, data) {
      this.$root.socket.emit(name, data, () => {});
    },
    hidenlayer() {
      $(".MyCataloge").removeClass("MyCatalogeActive");
      this.ShowCata = false;
      this.ShowBita = false;
      this.ShowResgister = false;
      this.ShowUsers = false;
      this.ShowTicketsDisp = false;
    },
    openView(view) {
      if (view == "Salir") {
        this.LogOut();
      } else {
        this.hidenlayer();
        switch (view) {
          case "Catalogo":
          case "Inicio":
            $(".MyCataloge").addClass("MyCatalogeActive");
            this.ShowCata = true;
            this.title = "Mayoristas";
            setTimeout(() => {
              this.$refs.proto.Trigger();
            }, 10);
            break;
          case "Cuenta":
            this.ShowResgister = true;
            this.title = "Cuenta";
            break;
          case "Usuarios":
            this.ShowUsers = true;
            this.title = "Usuarios";
            break;
          case "Boletos disponibles":
            this.ShowTicketsDisp = true;
            this.title = "Boletos disponibles";
            break;
          case "Mi Carrito":
          case "Bitacora":
            this.ShowBita = true;
            this.bitacoraTab = 0;
            this.badge = 0;
            this.title = this.titlesForCar[this.bitacoraTab];
            break;
          case "Ordenes de Pago":
            this.ShowBita = true;
            this.bitacoraTab = 1;
            this.title = this.titlesForCar[this.bitacoraTab];
            break;
          case "Autorizados":
            this.ShowBita = true;
            this.bitacoraTab = 2;
            this.title = this.titlesForCar[this.bitacoraTab];
            break;
          case "Bloqueados":
            this.ShowBita = true;
            this.bitacoraTab = 3;
            this.title = this.titlesForCar[this.bitacoraTab];
            break;
          case "Vencidos":
            this.ShowBita = true;
            this.bitacoraTab = 4;
            this.title = this.titlesForCar[this.bitacoraTab];
            break;
        }
        this.drawer = false;
      }
    },
    beginMessage(){
      axios
        .post(
          this.$root.myserver + this.$root.myport + "/Report/GetMessage", {})
        .then((response) => {
          let info = response.data

          if(info){
            if(info[0]){
              this.textnotification = info[0].info
            }
          }
        })
    },
    iniciar() {
      axios
        .post(
          this.$root.myserver + this.$root.myport + "/wholesaler/Lista",
          {},
          { headers: { "X-Access-Token": this.$root.token.token } }
        )
        .then((response) => {
          var sitio = [];
          var tikcets = [];
          var info = response.data;

          for (var x = 0; x < info.length; x++) {
            let res1 = sitio.find((itemx) => itemx.id === info[x].sitio);

            if (!res1) {
              sitio.push({
                id: info[x].sitio,
                name: info[x].name,
                img: info[x].img,
                activo: 0,
                cantidad: 0,
              });
            }
            tikcets.push({
              id: info[x].id,
              sitio: info[x].sitio,
              name: info[x].nombre,
              alias: info[x].alias,
              alias2: info[x].alias2,
              costo: info[x].costo,
              npool: info[x].npool,
              persontype: info[x].persontype,
              ini: info[x].Fini,
              fin: info[x].Ffin,
              cantidad: 0,
              type: 1,
            });
          }

          for (var y = 0; y < sitio.length; y++) {
            tikcets.push({
              id: 0,
              sitio: sitio[y].id,
              name: "Total",
              costo: 0,
              cantidad: 0,
              type: 0,
            });
          }

          //sitio.push.apply(sitio, sitio);
          this.PlaceX = sitio;
          setTimeout(() => {
            this.$refs.proto.Trigger();
          }, 100);

          this.tikcets = tikcets;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    AddCar() {
      this.badge = this.badge + 1;
      let car = [];
      var ttoalX = this.totalX;
      this.totalX = 0;

      for (var x = 0; x < this.tikcets.length; x++) {
        let cantidad = parseFloat(this.tikcets[x].cantidad);
        if (cantidad > 0 && this.tikcets[x].type != 0) {
          let v = this.tikcets[x];
          car.push(
            JSON.parse(
              JSON.stringify({
                cantidad: v.cantidad,
                id_ticket: v.id,
                sitio: v.sitio,
                npool: v.npool,
                persontype: v.persontype,
              })
            )
          );
        }
        this.tikcets[x].cantidad = 0;
      }

      this.SendCar(car);
    },
    LogOut() {
      this.$swal({
        title: "Esta seguro?",
        text: "Realmente quieres salir del sistema!",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "primary",
        confirmButtonText: "SI",
        denyButtonColor: "#dd8159",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$root.socket.disconnect();
          this.$root.Exit();
        }
      });
    },
    SendCar(item) {
      let data = { item: item, token: this.$root.token.token };
      this.socketX("Precarrito", data);
      this.PlaceX.map((item) => {
        item.cantidad = 0;
      });
    },
    fixinfo(info) {
      if (info) {
        for (var x = 0; x < info.length; x++) {
          info[x].place = JSON.parse(info[x].place);
        }
      }

      return info;
    },
    changeTitle: function (val) {
      this.title = this.titlesForCar[val];
    },
    refreshToken: function (token) {
      this.$root.token = token;
      this.$cookies.set("user", token);
      this.avatar = this.$root.token.avatar;
      this.rol = this.$root.token.subrol;
      this.NameCorp = this.$root.token.corp;
      this.TypeUser = this.$root.token.cargo;
      this.openView("Catalogo");
    },
    alertLogo: function (val) {
      try {
        var temp = val.split("/");
        var temp1 = temp[5].split("?");
        return temp1[0] == "0.png" ? true : false;
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.pointman:hover{
  cursor: pointer;
  color: #8f8f8f;
}
.myAlert {
  position: absolute;
  z-index: 99;
  top: calc(100vh - 80px);
  left: 0px;
  width: 100%;
  padding: 0px 10px;
}

.footerX .child1 {
  font-size: 20px;
}

.myAlert .child2 {
  font-size: 12px;
}

#Catologo {
  min-width: 100vw;
}
.MasterLoadar {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(9, 19, 15, 0.3);
  display: none;
  color: white;
}
.IconX {
  zoom: 90%;
}
.ImgAvartDiv {
  width: 100%;
  height: 121px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.ImgCorp {
  max-width: 240px !important;
  max-height: 121px !important;
}
.TempoCardStyle {
  background: #272727;
  color: white;
}

.MyCardShop .v-card__title {
  height: 43px !important;
  font-size: 1.1rem !important;
}
.RemoveBackground {
  background: #e4e9f7 !important;
}
.MyCataloge {
  margin-top: -6px;
  width: 100vw;
  overflow: auto;
  /* height: calc(100vh - 57px); */
  height: calc(100vh);
  /* background: #272727 !important; /* rgb(228 233 247); */
}

.MyCatalogeActive {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

.active-dropdown {
  color: white !important;
}

.scroll-block-x {
  overflow-x: hidden;
}

.imageLogo {
  position: relative !important;
}

.MyAlertNotification {
  position: absolute;
  top: calc(100% - 23px);
  left: 0;
  height: 22px;
  width: 100%;
  color: white;
  opacity: 0.7;
  background-color: #272727;
}

.footerX{
  position: absolute;
  z-index: 100;
  top: calc(100vh - 150px);
  background: #b52323;
  width: 100%;
}

.footerXX{
  position: absolute;
    z-index: 100;
    top: calc(100vh - 110px);
    background: #b52323;
    width: 100%;
    padding-right: 50px;
}

@media (max-width: 1594px) {
  .footerX{
  top: calc(100vh - 190px);
  }
}

@media (max-width: 1043px) {
  .footerX{
  top: calc(100vh - 218px);
  }
}

@media (max-width: 960px) {
  /* .MyCataloge {
    height: calc(100vh - 49px) !important;
  } */
  .BtnCar {
    zoom: 80%;
  }
/*   .myAlert .child1 {
    font-size: 12px;
  }

  .myAlert .child2 {
    font-size: 10px;
  } */
}

@media (max-width: 826px) {
  .footerX{
    zoom:80% !important;
    top: calc(100vh - -3px) !important;
  }
}

@media (max-width: 666px) {
  .footerX{
    top: calc(100vh - 27px) !important;
  }
}

@media (max-width: 533px) {
  .footerX{
    top: calc(100vh - 60px) !important;
  }
  .MyCataloge {
    height: calc(100vh - -44px);
  }
}

/* @media only screen and (min-device-width: 480px) {
  .myAlert {
    top: calc(100vh - 80px);
  }
} */
</style>
