<template>
  <div id="register">
    <v-card class="card-mayo-form-container" style="zoom: 90%" max-width="730px">
      <v-card-title dark class="text-h5 TempoCardStyle">
        Datos de corporativo
      </v-card-title>
      <FormMayoristas class="py-8 px-6" />
    </v-card>
  </div>
</template>

<script>
import FormMayoristas from "./Form-mayoristas.vue";
export default {
  data() {
    return { };
  },
  components: {
    FormMayoristas,
  },
};
</script>

<style scoped>
#register {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  min-height: 100%;
  padding: 1.5%;
}

.TempoCardStyle {
  background: #272727;
  color: white;
}
@media (max-width: 599px) {
  #register {
    padding: 0px;
  }

  .card-mayo-form-container {
    border-radius: 4px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    max-height: 95vh;
  }
}
</style>