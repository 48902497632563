var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"box-shadow":"none !important"}},[_c('v-card-title',{staticStyle:{"padding":"5px 25px 5px 26px"}},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){_vm.dialog = true}}},[_c('span',{staticClass:"material-icons",staticStyle:{"padding-right":"5px"}},[_vm._v(" visibility ")]),_vm._v(" Especificaciones de usuarios ")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"label":"","color":"orange","placeholder":"Buscar","prepend-icon":"mdi-magnify","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"styled-table m-4 mt-2",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","sort-desc":[true, true],"search":_vm.search,"multi-sort":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index,staticStyle:{"color":"black"},style:({ 'background-color': _vm.backgrondRowTable(index), 'border-top':'2px solid #75695f' }),on:{"click":function($event){return _vm.edit(item, index)}}},[_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.clave))]),_c('td',[_vm._v(_vm._s(item.rolDescription))]),_c('td',[_vm._v(" "+_vm._s(item.name + " " + item.name2 + " " + item.name3)+" ")]),_c('td',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"success",attrs:{"x-small":"","fab":"","elevation":"1","color":item.active ? '' : '#f07f3a !important'},on:{"click":function($event){return _vm.changeStatus(item)}}},'v-btn',attrs,false),on),[(item.active)?_c('span',{staticClass:"material-icons"},[_vm._v(" person ")]):_c('span',{staticClass:"material-icons"},[_vm._v(" person ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.active ? 'Desactivar Usuario': 'Activar Usuario'))])])],1)])}),0)]}}])}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","scrollable":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Especificaciones de usuarios")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"container-user-description"},[_c('h2',{staticClass:"text-center mt-3 title-user"},[_vm._v("Roles")]),_c('p',{staticClass:"text-center subtitle-user"},[_vm._v(" Los roles indican clases de usuarios que tienen asignados ciertos subconjuntos de tareas, ya sea por elección propia o como resultado de la organización en la que se encuentran ")]),_c('v-simple-table',{staticClass:"w-100 my-6"},[_c('tbody',[_c('tr',{staticStyle:{"background-color":"rgba(255, 165, 0, 0.4)"}},[_c('td',[_c('v-simple-table',[_c('thead',[_c('tr',{staticStyle:{"background":"rgb(253 142 48 / 22%)","color":"black"}},[_c('td',{staticClass:"MyTdPadding",staticStyle:{"font-size":"19px"},attrs:{"align":"center"}},[_c('strong',[_vm._v("Funciones")])]),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('strong',[_vm._v("Cuenta Maestra ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('sup',_vm._g(_vm._b({},'sup',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-help-circle-outline ")])],1)]}}])},[_c('span',[_vm._v(" Es la cuenta principal, para cada corporación solo puede existir una, esta es creada al momento del registro del mayorista. "),_c('br'),_vm._v(" Por eso no aparece para ser establecida en un rol. ")])])],1),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('strong',[_vm._v("Administrador")])]),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('strong',[_vm._v("Coordinador")])]),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('strong',[_vm._v("Supervisor")])])])]),_c('tbody',_vm._l((_vm.contentTable),function(item,index){return _c('tr',{key:("" + index)},[_c('td',{staticClass:"MyTdPadding"},[_vm._v(" "+_vm._s(item.title)+" "),_c('v-tooltip',{attrs:{"right":"","max-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('sup',[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.description)+" ")])])],1),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('v-icon',{attrs:{"color":item.cuentaMaestra ? 'success' : 'warning'}},[_vm._v(" mdi-"+_vm._s(item.cuentaMaestra ? 'check-bold': 'close-thick')+" ")])],1),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('v-icon',{attrs:{"color":item.administrador ? 'success' : 'warning'}},[_vm._v(" mdi-"+_vm._s(item.administrador ? 'check-bold': 'close-thick')+" ")])],1),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('v-icon',{attrs:{"color":item.coordinador ? 'success' : 'warning'}},[_vm._v(" mdi-"+_vm._s(item.coordinador ? 'check-bold': 'close-thick')+" ")])],1),_c('td',{staticClass:"MyTdPadding",attrs:{"align":"center"}},[_c('v-icon',{attrs:{"color":item.supervisor ? 'success' : 'warning'}},[_vm._v(" mdi-"+_vm._s(item.supervisor ? 'check-bold': 'close-thick')+" ")])],1)])}),0)])],1)])])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }