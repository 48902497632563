<template>
  <div>
    <v-card style="margin-top: 7px;" flat>
      <v-tabs v-model="tab" dark>
        <v-tabs-slider></v-tabs-slider>

        <v-tab id="tab1" key="tab-1">
          <v-icon class=" ma-2">mdi-cart</v-icon>
          Mi carrito
        </v-tab>
        <v-tab id="tab2" key="tab-2">
          <v-icon class=" ma-2">mdi-clipboard-text-clock-outline</v-icon>
          Ordenes de Pago
        </v-tab>
        <v-tab id="tab3" key="tab-3">
          <v-icon class=" ma-2">mdi-clipboard-check-multiple-outline</v-icon>
          Autorizados
        </v-tab>
        <v-tab id="tab4" key="tab-4">
          <v-icon class=" ma-2">mdi-cancel</v-icon>
          Bloqueados
        </v-tab>
        <v-tab id="tab5" key="tab-5">
          <v-icon class=" ma-2">mdi-calendar-clock</v-icon>
          Vencidos
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="tab-1">
          <PreCarrito  :items="items2" :totalx="totalx"/>
        </v-tab-item>

        <v-tab-item key="tab-2">
          <TableBita ref="TableBita1" :headers="headers2" :items="items" :ShowCheck="ShowCheck" :tab="tab"/>
        </v-tab-item>

        <v-tab-item key="tab-3">
          <TableBita :headers="headers3" :items="items" :ShowCheck="ShowCheck" :tab="tab"/>
        </v-tab-item>

        <v-tab-item key="tab-4">
          <TableBita :headers="headers4" :items="items" :ShowCheck="ShowCheck" :tab="tab"/>
        </v-tab-item>

        <v-tab-item key="tab-5">
          <TableBita :headers="headers5" :items="items" :ShowCheck="ShowCheck" :tab="tab"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import TableBita from "../mayoristas/TablaBitacora.vue";
import PreCarrito from '../mayoristas/TablePreCarrito.vue'

export default {
  components: {
    TableBita,
    PreCarrito
  },
  data() {
    return {
      totalx:0,
      items2: [],
      tab: 0,
      rol: 0,
      ShowCheck: true,
      headers2: [
        { text: '', value: 'icon'},
        { text: "Línea de Referencia", value: "linearef" },
        { text: "Fecha de Vencimiento", value: "fvenci" },

        { text: "Fecha de Creacion", value: "fecha" },
        { text: "Costo Total", value: "total", align:"right" },
        { text: "Solicito", value: "solicito", align:"center" }
      ],
      headers3: [
        { text: '', value: 'icon'},
        { text: "Línea de Referencia", value: "linearef" },
        { text: "Fecha de Vencimiento", value: "fvenci" },
        { text: "Fecha de Creacion", value: "fecha" },
        { text: "Costo Total", value: "total", align:"right" },
        { text: "Solicito", value: "solicito", align:"center" },
        { text: "Fecha de Autorizacion", value: "f_autorizacion", align:"center" }
      ],
      headers4: [
        { text: '', value: 'icon'},
        { text: "Línea de Referencia", value: "linearef" },
        { text: "Fecha de Vencimiento", value: "fvenci" },
        { text: "Fecha de Creacion", value: "fecha" },
        { text: "Costo Total", value: "total", align:"right" },
        { text: "Fecha de Autorizacion", value: "f_autorizacion", align:"center" },
        { text: "Fecha de Bloqueo", value: "f_autorizacion", align:"center" },
      ],
      headers5: [
        { text: '', value: 'icon'},
        { text: "Línea de Referencia", value: "linearef" },
        { text: "Fecha de Vencimiento", value: "fvenci" },
        { text: "Fecha de Creacion", value: "fecha" },
        { text: "Costo Total", value: "total", align:"right" },
      ],
      items: [],
    };
  },
  props: ["bitacoraTab"],
  watch: {
    tab: function () {
      if(this.tab == 0){
        this.ShowCheck = true
      }else{
        this.ShowCheck = false
      }
      this.inibitacora()
      //this.$parent.changeTitle(this.tab)
      this.$parent.$parent.changeTitle(this.tab)
    },
    bitacoraTab: function (val) {
      this.tab = val
    }
  },
  mounted() {
    this.iniPreCarrito()
    this.tab = this.bitacoraTab
    this.rol = this.$root.token.subrol
    if (this.rol < 3) {
      this.headers2.push({ text: "Imprimir Orden", value: "id", align:"center" });
      this.headers3.push({ text: "Imprimir Recibo", value: "id", align:"center" });
    }
  },
  methods: {
    iniPreCarrito(){
      this.$root
        .post("/wholesaler/GetPreCarrito", { entidad: this.tab })
        .then((response) => {
          var info = this.fixinfo(response.data[1])
          this.items2 = info
          
          if(response.data[2][0]){
            this.totalx = response.data[2][0].total
          }else{
            this.totalx = 0
          }
        });
    },
    inibitacora() {
      this.$root
        .post("/wholesaler/GetTransCar", { entidad: this.tab })
        .then((response) => {
          var info = this.fixinfo(response.data[0]);
          this.items = info;
        });
    },
    fixinfo(info) {
      if(info){
        for (var x = 0; x < info.length; x++) {
          info[x].place = JSON.parse(info[x].place);
        }
      }
      
      return info;
    },
  },
};
</script>

<style scoped>
</style>