const $ = require('jquery')

const setColorHeadFooter = function(){
  const fondo = "#272727 ";
  const text = "white ";
  $(".v-data-table-header").css({ background: fondo });
  $(".v-data-table-header tr th").css({ color: text });
  $(".v-data-table-header tr th i").css({ color: text });
  $(".v-data-table-header tr th span").css({ color: text });
  
  $(".v-data-footer").css({ background: fondo });
  $(".v-data-footer div").css({ color: text });
  $(".v-data-footer div i").css({ color: text });
  $(".v-data-footer div button span .v-icon").css({ color: text });
}

const backgrondRowTable = function(index){
  let colorx;
  var background1 = "rgb(255 210 174 / 58%)";
  var background2 = "rgb(255 232 213 / 58%)";

  if (index % 2 === 0 || index == 0) {
    colorx = background1;
  } else {
    colorx = background2;
  }
  return colorx;
}


module.exports = {
  setColorHeadFooter,
  backgrondRowTable
}