const formatPrice = function(value) {
  if(!value){
    value =  0
  }
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  value = value.toFixed(2)
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const formatNum = function(value) {
  if(!value){
    value =  0
  }
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

module.exports = {  
  formatPrice,
  formatNum
}