<template>
  <v-card style="box-shadow: none !important">
    <v-card-title style="padding: 5px 25px 5px 26px;">
      <v-btn
        depressed
        color="primary"
        v-if="tab == 0 && selected.length > 0"
        @click="validar(selected)"
      >
        <span class="material-icons" style="padding-right:5px;">
          done
        </span>
        Validar
      </v-btn>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        label=""
        color="orange"
        placeholder="Buscar"
        prepend-icon="mdi-magnify"
        clearable
        single-line
        hide-details
        style="max-width: 400px"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="styled-table m-4 mt-2"
      :sort-by="['fecha', 'hora']"
      :sort-desc="[true, true]"
      :search="search"
      multi-sort
    >
      <template v-slot:body="{ items }">
        <tbody>
          <template v-for="(item, index) in items">
            <tr
              :key="'ReportPayment-' + index"
              @click="clickrow(item.id)"
              class="pointman"
              style="color: black"
              :style="{ 'background-color': backgrondRowTable(index), 'border-top':'2px solid #75695f' }"
            >
              <td style="max-width: 10px">
                <v-icon class="ma-2" v-if="item.id == expanded">mdi-minus</v-icon>
                <v-icon class="ma-2" v-if="item.id != expanded">mdi-plus</v-icon> 
              </td>
              <td>
                <strong>{{ item.linearef }}</strong>
              </td>
              <td>
                <strong :style="tab == 4 ? 'color: red': ''">{{ item.fvenci }}</strong>
              </td>
              <td>
                <div style="color:green" v-if="tab == 1">
                  <strong>
                    {{ item.fecha }}
                  </strong>
                </div>
                <div v-if="tab != 1">
                  {{ item.fecha }}
                </div>
              </td>
              <td align="right">
                <strong>${{ formatPrice(item.total) }} MXN</strong>
              </td>
              <td align="center" v-show="tab != 4" v-if="tab != 3">
                <v-btn
                  icon
                  color="blue"
                  v-if="!item.estatus"
                  @click="validar(item)"
                >
                  <span class="material-icons" style="font-size: 35px">
                    done
                  </span>
                </v-btn>

                <v-btn
                  icon
                  color="red"
                  v-if="!item.estatus"
                  class="ma-2"
                  @click="eliminar(item)"
                >
                  <span class="material-icons" style="font-size: 35px">
                    delete
                  </span>
                </v-btn>

                <div v-if="tab == 1 || tab == 2">
                  <div style="color:green" v-if="tab == 1">
                    <strong>
                      {{ item.solicito }}
                    </strong>
                  </div>
                  <div v-if="tab == 2">
                    {{ item.solicito }}
                  </div>
                </div>
              </td>

              <td align="center" v-if="tab == 2 || tab == 3">
                <div v-if="tab == 2" style="color:blue;">
                  <strong>
                    {{ item.f_autorizacion }}
                  </strong>
                </div>
                <div v-if="tab == 3">
                  {{ item.f_autorizacion }}
                </div>
              </td>

              <td align="center" v-show="rol < 3" v-if="tab == 1" style="min-width: 170px;">
                <div>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue"
                        @click="print(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i
                          class="bx bxs-printer"
                          style="font-size: 20px;color:white;"
                        ></i>
                      </v-btn>
                    </template>
                    <span>Imprimir orden de pago</span>
                  </v-tooltip>
                </div>
              </td>

              <td align="center" v-show="rol < 3" v-if="tab == 2">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      @click="print(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i
                        class="bx bxs-printer"
                        style="font-size: 20px;color:white;"
                      ></i>
                    </v-btn>
                  </template>
                  <span>Imprimir recibo de pago</span>
                </v-tooltip>
              </td>

              <td align="center" v-if="tab == 3">
                <div style="color:red;">
                  <strong>{{ item.f_bloqueo }}</strong>
                </div>
              </td>
            </tr>

            <tr
              :key="'ReportPayment2-' + index"
              v-if="item.id == expanded"
              style="background-color: white !important"
            >
              <td colspan="20">
                <v-simple-table
                  style="zoom: 90%"
                  v-for="(item2, index2) in item.place"
                  :key="'SubReportPayment2-' + index + '-' + index2"
                >
                  <thead>
                    <tr>
                      <td
                        style="background: white; color: black; padding: 0 !important; padding-top: 20px !important; padding-left: 5px !important; height: 40px; font-size:15px;"
                      >
                        <strong>{{ item2.shortname }}</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="background-color: rgba(255, 165, 0, 0.4);">
                      <td>
                        <v-simple-table style="zoom: 90%">
                          <thead>
                            <tr
                              style="background: rgb(253 142 48 / 22%); color: black"
                            >
                              <td style="width:50%;">
                                <strong>Nombre del Boleto</strong>
                              </td>
                              <td align="center" style="width:10%">
                                <strong>Cantidad</strong>
                              </td>
                              <td align="right" style="width:20%;">
                                <strong>Precio Unitario</strong>
                              </td>
                              <td align="right" style="width:20%;">
                                <strong>Costo</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item3, index3) in item2.transaccion"
                              :key="
                                'SubReportPayment2-' +
                                  index +
                                  '-' +
                                  index2 +
                                  '-' +
                                  index3"
                            >
                              <td>
                                {{ item3.nombre }}  <span v-if="item3.alias">({{ item3.alias }})</span>
                              </td>
                              <td align="center">
                                {{ item3.cantidad }}
                              </td>
                              <td align="right">
                                <strong
                                  >${{ formatPrice(item3.costo) }} MXN</strong
                                >
                              </td>
                              <td align="right">
                                <strong
                                  >${{ formatPrice(item3.total) }} MXN</strong
                                >
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatPrice } from "@/utils/NumericTools.js";
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";
import $ from "jquery";
import VueHtml2pdf from "vue-html2pdf";

//{name:'CLABE INTERBANCARIA',value:'021910040667602404'},
export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      formatPrice, backgrondRowTable,
      dataBank: [
        {name:'CLABE INTERBANCARIA',value:'021180550300053575'},
        {name:'INSTITUCIÓN BANCARIA',value:'HSBC'},
        {name:'BENEFICIARIO',value:'SECRETARÍA DE ADMINISTRACIÓN Y FINANZAS'},
        {name:'RFC',value:'SHA840512SX1'},
      ],
      menu2: false,
      Iditem: null,
      LineaRef: null,
      rol: 0,
      dateRef: new Date().toISOString().slice(0, 10),
      dialog: false,
      search: "",
      flag: false,
      selected: [],
      TmpSelected: [],
      flagSelect: false,
      expanded: "",
      detPrintItem: [],
      nowDate: "",
    };
  },
  props: ["headers", "items", "ShowCheck", "tab"],
  mounted() {
    setColorHeadFooter();
    this.nowDate = this.formatDate(new Date());
    this.rol = this.$root.token.subrol
  },
  watch: {
    tab: function() {
      setTimeout(() => {
        setColorHeadFooter();
        this.cahngtab();
        this.expanded = ""
      }, 1);
    },
    search: function (val) {
      this.expanded = ""
    }
  },
  methods: {
    formatDate: function(d) {
      let formatted_date =
        ("0" + d.getDate()).slice(-2) +
        "/" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        d.getFullYear();
      return formatted_date;
    },
    cahngtab() {
      if (this.tab == 0) {
        this.flagSelect = false;
        this.selected = JSON.parse(JSON.stringify(this.TmpSelected));
      } else {
        if (this.flagSelect == false) {
          this.TmpSelected = JSON.parse(JSON.stringify(this.selected));
        }
        this.selected = [];
        this.flagSelect = true;
      }
    },
    validar(item) {
      this.flag = true;
      this.MSGBOXFIX("info", "¿Desea validar esta transaccion?", null, 1, item);
    },
    eliminar(item) {
      this.flag = true;
      this.MSGBOXFIX(
        "question",
        "¿Desea eliminar esta transaccion?",
        null,
        2,
        item
      );
    },
    MSGBOXFIX(icon, title, text, opt, item) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "SI",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ChangeStatus(item, opt);
        }
      });
      //Fix bug add padging
      $("body").css({ "padding-right": "0px" });
    },
    ChangeStatus(item, opt) {
      var temporary = "";
      var type = 0;

      if (item.length) {
        for (var i = 0; i < item.length; i++) {
          if (i > 0) {
            temporary = temporary + ",";
          }
          temporary = temporary + item[i].id;
        }
      } else {
        temporary = "" + item.id;
        type = 1;
      }

      switch (opt) {
        case 1:
          //Voy a enviar de carrito a pendienes
          var data = { data: temporary, opt: 1, type: type };
          this.UpdateStatusTransMayo(data);
          break;

        case 2:
          //Voy a enviar a eliminar del carrito
          var data = { data: temporary, opt: 0, type: type };
          this.UpdateStatusTransMayo(data);
          break;
      }
    },
    UpdateStatusTransMayo(data) {
      this.$root
        .post("/wholesaler/UpdateStatusTransMayo", { data })
        .then((response) => {
          this.$parent.$parent.$parent.$parent.inibitacora();
          let text;

          switch (data.opt) {
            case 0:
              text = "Se elimino la transaccion correctamente";
              break;
            case 1:
              if (data.type == 1) {
                text = "Se solicito la transaccion con exito";
              } else {
                text = "Se solicito las transacciones con exito";
              }
              break;
          }

          setTimeout(() => {
            this.selected = [];
          }, 1);

          this.$root.swalAlert("success", text, null);
        });
    },
    clickrow(id) {
      if (this.flag == false) {
        this.expanded = this.expanded == id ? "" : id;
      } else {
        this.flag = false;
      }
    },
    print: function(item) {
      this.flag = true;
      this.detPrintItem = Object.assign({}, item);

      this.$session.remove("tipo")
      var tipoX = {id:item.id, estatus: item.estatus, token:this.$root.token.token, idCorp:this.$root.token.idCorp, format:1}
      this.$session.set("tipo", tipoX);
      
      setTimeout(() => {  
        let routeData = this.$router.resolve({name: 'PdfGenerator'});
        window.open(routeData.href, '_blank');
        /* var dir
        dir = this.$root.myserver.slice(0, -1) + '/tcpdf/reports/GeneratorPdf.php?t='+ this.$root.token.token + '&s=' + item.estatus + '&f='+item.id
        window.open(dir, '_parent'); */
        //this.$refs.DownloadComp.generatePdf();
          /* setTimeout(() => {
            this.$cookies.remove("tipo");
          }, 1000); */
      }, 10);
    },
  },
};
</script>

<style scoped>
.container-pdf {
  width: 100%;
  background-color: white;
  padding: 30px 40px;
}
</style>
