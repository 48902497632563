<template>
<div>


  <div class="Mayomain">
    <div class="MayoThumbnailDiv noselect">
      <div
        :data-page="index1"
        :class="'nav-' + index1"
        class="nav-elem"
        :style="{ backgroundImage: 'url(\'' + item.img + '\')' }"
        v-for="(item, index1) in PlaceX"
        :key="`nav-elem-${index1}`"
        @click="ActivaSitio(item, null, index1)"
      >
        <div class="title">
          {{ item.name }}
        </div>
        <div class="barprice">${{ formatPrice(item.cantidad) }} MXN</div>
      </div>
    </div>

    <div class="MayoBgContainer">
      <div
        class="section"
        :class="'section-' + index1"
        v-for="(item, index1) in PlaceX"
        :key="`section-${index1}`"
      >
        <div
          class="bg-part bg0"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg1"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg2"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg3"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg4"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg5"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg6"
          :style="getZizeBackground(item.img)"
        ></div>
        <div
          class="bg-part bg7"
          :style="getZizeBackground(item.img)"
        ></div>
      </div>
    </div>

    <div class="DivTickets">
      <v-row>
        <v-col cols="12" class="Ticketplace">
          <hr style="background: white; margin-top: 0; margin-bottom: 8px;">
          {{place}}
          <hr style="background: white; margin-bottom: 0; margin-top: 8px;">
        </v-col>
        <v-col
          class="MyCardShop"
          cols="12"
          v-for="(item, index1) in tikcets2"
          :key="`tikcets-${index1}`"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              style="height: 100%; opacity: 1"
              elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              class="CardFormat"
            >
              <div v-if="item.type">
                <div class="CardTitel">
                  <strong>{{ item.alias }}</strong> <br>
                  <small v-if="item.alias2">({{ item.alias2 }})</small>
                </div>
                <div align="right" style="padding-right: 15px; font-size:13px;">
                  <strong> Vencimiento: {{item.fin}} </strong>
                </div>
                <div class="CardMoney">MXN ${{ formatPrice(item.costo) }}</div>
                <div class="CardInput" v-if="rol < 3">
                  <div class="Lebox">
                    <div>
                      <v-btn class="Lebtn LeLeft" outlined color="indigo" @click="addinput(item)">
                        <span class="material-icons">
                          add
                        </span>
                      </v-btn>
                    </div>
                    <div>
                      <input
                        v-model="item.cantidad"
                        @keypress="isNumber($event)"
                        @keyup="changeinput(item)"
                        class="MyInputNumber"
                        maxlength="8"
                      />
                    </div>
                    <div>
                      <v-btn class="Lebtn LeRigth" outlined color="indigo" @click="lessinput(item)">
                        <span class="material-icons">
                          remove
                        </span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </div>
</div>
</template>

<script>
import { formatPrice } from "@/utils/NumericTools.js";
import $ from "jquery";

export default {
  data() {
    return {
      formatPrice,
      tikcets2: [],
      ontoy: 0,
      place:"",
      ini: true,
      rol: 0,
    };
  },
  props: ["PlaceX", "tikcets"],
  mounted() {    
    if (this.$cookies.get("user")) {
      this.$root.token = this.$cookies.get("user");
    } else {
      this.$router.push("/");
    }

    this.rol = this.$root.token.subrol
  },
  computed:{
    getZizeBackground() {
      return image => {
        return{
          'backgroundImage': 'url(\'' + image + '\')', 
          'background-size' : this.getChromeVersion() <= 99 ? '100vw 90vh' : '112vw 90vh'
          //'background-size' : this.getChromeVersion() <= 99 ? '100vw 100vh' : '112vw 100vh'
        }
      }
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    Trigger() {
      if (this.PlaceX.length > 0) {
        $(".section-0").css({ "z-index": 1 });
        $(".section-0 .bg-part").addClass("act");
        $(".nav-elem").removeClass("ActiveNav-ele");
        $(".nav-0").addClass("ActiveNav-ele");

        var resultado = this.tikcets.filter(
          (itemx) => itemx.sitio === this.PlaceX[0].id
        );
        
        this.place    = this.PlaceX[0].name
        this.tikcets2 = resultado;
      }
    },
    ActivaSitio(item, opt, index1) {
      var x = this.ontoy;

      if (this.ontoy == index1) {
        return false;
      }

      $(".DivTickets").fadeOut();
      $(".nav-elem").removeClass("ActiveNav-ele");
      $(".nav-" + index1).addClass("ActiveNav-ele");

      if (this.ini) {
        $(".section-0 .bg-part").removeClass("act");
        this.ini = false;
      }

      $(".section").css({ "z-index": 0 });

      setTimeout(() => {
        $(".section-" + x + " .bg-part").removeClass("act");
      }, 800);

      $(".section-" + index1).css({ "z-index": 1 });
      $(".section-" + index1 + " .bg-part").addClass("act");
      this.ontoy = index1;

      setTimeout(() => {
        this.place    = item.name
        var resultado = this.tikcets.filter((itemx) => itemx.sitio === item.id);
        this.tikcets2 = resultado;
        $(".DivTickets").fadeIn();
      }, 500);
    },
    changeinput(value) {
      if (value.cantidad == "") {
        value.cantidad = 0;
      }
      value.cantidad = parseInt(value.cantidad)
      this.agrego()
    },
    addinput(item){
      item.cantidad = parseInt(item.cantidad) + 5
      this.agrego()
    },
    lessinput(item){
      if((parseInt(item.cantidad) - 5) < 0 ){
        item.cantidad = 0
      }else{
        item.cantidad = parseInt(item.cantidad) - 5
      }
      
      this.agrego()
    },
    agrego() {
      var total = 0;
      var totalx = 0;
      for (var x = 0; x < this.tikcets2.length; x++) {
        if (this.tikcets2[x].type == 1) {
          if (this.tikcets2[x].cantidad != "") {
            total =
              total +
              this.tikcets2[x].costo * parseInt(this.tikcets2[x].cantidad);
          }
        }
      }

      var resultado1 = this.tikcets2.find((itemx) => itemx.type === 0);
      resultado1.cantidad = total;

      var resultado = this.tikcets.filter((itemx) => itemx.type === 0);
      for (var x = 0; x < resultado.length; x++) {
        if (resultado[x].cantidad == "") {
          resultado[x].cantidad = 0;
        }
        totalx = totalx + resultado[x].cantidad;
        var resultado1 = this.PlaceX.find(
          (itemx) => itemx.id === resultado[x].sitio
        );
        if (resultado[x].cantidad) {
          if (resultado1) {
            resultado1.activo = 1;
            resultado1.cantidad = resultado[x].cantidad;
          } 
        } else {
          if (resultado1) {
            resultado1.activo = 0;
            resultado1.cantidad = 0;
          } 
        }
      }
      this.$parent.$parent.totalX = totalx;
      this.$parent.totalX = totalx;
    },
    getChromeVersion () {     
      var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
      return raw ? parseInt(raw[2], 10) : false;
  }
  },
};
</script>

<style scoped>
.Ticketplace{
  color: white;
  text-align: center;
  font-weight: bold;
}
.Lebtn {
  width: 30px !important;
  min-width: 30px !important;
  margin: 0 !important;
  height: 35px !important;
  background: #ffffff;
}
.Lebtn:hover{
  background: orange !important;
}
.Lebtn:hover span{
  color: white !important;
}
.Lebtn span {
  font-size: 22px;
  margin-left: -2px;
  font-weight: bold;
}
.LeRigth {
  border-radius: 5px 0px 0px 5px !important;
  border-right: none !important;
}
.LeLeft {
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}
.Lebox {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-wrap: nowrap;
}
.CardFormat .CardInput {
  padding: 0px 10px 10px 10px;
}
.CardFormat .MyInputNumber {
  font-size: 18px;
  background: white;
  padding: 3px;
  border: 1px solid black;
  width: 100%;
}
.CardFormat .CardMoney {
  font-size: 18px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
}
.CardFormat .CardTite2 {
  font-size: 20px;
  line-height: 1.4rem !important;
  padding-bottom: 0;
  font-weight: bold;
}
.CardFormat .CardTitel {
  font-size: 15px;
  line-height: 1.4rem !important;
  padding-bottom: 0;
  padding: 10px;
}
.CardFormat {
  /*min-height: 140px;*/
  background: url("../../assets/backgrondReport2.png");
  background-size: 100%;
  height: 100%;
}
.DivTickets {
  position: absolute;
  width: 300px;
  background: rgb(0 0 0 / 50%);
  left: calc(100% - 299px);
  height: calc(100% - 85px);
  z-index: 1;
  padding: 20px;
  overflow: auto;
  direction: rtl !important;
}
.section {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  height: 100%;
}
.bg-part {
  transform: translateY(-100%);
  height: 100%;
  width: 12.5%;
  /*background-size: cover;*/
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  position: absolute;
  background-attachment: fixed;
  margin-top: -10px;
}
.bg0 {
  margin-left: 0;
  background-position: 0% 50%;
  transition-duration: 1s;
  transition-property: transform;
}
.bg1 {
  margin-left: 12.5%;
  background-position: 12.5% 50%;
  transition-duration: 0.8s;
  transition-property: transform;
}
.bg2 {
  margin-left: 25%;
  background-position: 25% 50%;
  transition-duration: 0.6s;
  transition-property: transform;
}
.bg3 {
  margin-left: 37.5%;
  background-position: 37.5% 50%;
  transition-duration: 0.4s;
  transition-property: transform;
}
.bg4 {
  margin-left: 50%;
  background-position: 50% 50%;
  transition-duration: 0.6s;
  transition-property: transform;
}
.bg5 {
  margin-left: 62.5%;
  background-position: 62.5% 50%;
  transition-duration: 0.8s;
  transition-property: transform;
}
.bg6 {
  margin-left: 75%;
  background-position: 75% 50%;
  transition-duration: 1s;
  transition-property: transform;
}
.bg7 {
  margin-left: 87.5%;
  background-position: 87.5% 50%;
  transition-duration: 1.2s;
  transition-property: transform;
}
.act {
  transform: translateY(0%);
}
.MayoBgContainer {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100% - 85px);
  overflow: auto;
  z-index: 1;
}
.nav-elem {
  position: relative;
  width: 100%;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position-x: 50%;
  cursor: pointer;
  border-bottom: 4px solid grey;
  transition-duration: 0.5s;
  transition-property: height;
}
.ActiveNav-ele {
  height: 15.4rem;
  background-position-y: -22px;
}
.nav-elem .title {
  background: rgba(255, 165, 0, 0.9);
  color: white;
  font-size: 15px !important;
  padding: 2px;
  text-align: center;
  line-height: 1.5rem;
}
.nav-elem .barprice {
  position: relative;
  background: black;
  color: white;
  font-size: 15px;
  text-align: center;
  top: calc(100% - 50px);
}
.nav-elem:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition: background 0.2s;
  will-change: background;
}
.nav-elem:hover:after {
  background: transparent;
}
.Mayomain {
  width: 100%;
  height: calc(100% - 83px);
}
.MayoThumbnailDiv {
  position: absolute;
  height: calc(100% - 83px);
  width: 280px;
  overflow: auto;
  padding-top: 6px;
  z-index: 2;
}

/* width */
.DivTickets::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.DivTickets::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.DivTickets::-webkit-scrollbar-thumb {
  background: #fa9f31;
  border-radius: 5px;
}

/* Handle on hover */
.DivTickets::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

/* width */
.MayoThumbnailDiv::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.MayoThumbnailDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.MayoThumbnailDiv::-webkit-scrollbar-thumb {
  background: #fa9f31;
  border-radius: 5px;
}

/* Handle on hover */
.MayoThumbnailDiv::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@media (max-width: 700px) and (orientation: landscape),
  (max-width: 530px) and (orientation: portrait) {
  .Mayomain {
    margin-top: 6px;
    height: calc(100% - 6px);
  }
  .DivTickets,
  .MayoThumbnailDiv {
    zoom: 50%;
  }
  .bg-part{
    margin-top: -30px;
  }
  .DivTickets {
    height: calc(100% - 200px);
  }
  .MayoThumbnailDiv {
    padding-top: 0px;
    height: calc(100% - 200px);
  }
  .MyCataloge {
    height: calc(100vh - 138px) !important;
  }
  .MayoBgContainer {
   height: calc(100% - 100px) !important;
  }
}
</style>
