<template>
  <v-card style="margin-top: 7px;" flat id="users">
    <v-tabs v-model="tab" dark>
      <v-tabs-slider></v-tabs-slider>

      <v-tab id="tab1" key="tab-1">
        <v-icon class=" ma-2">people</v-icon>
        Usuarios
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="tab-1">
        <tableMayo :headers="headers" :items="content" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import axios from "axios";
import tableMayo from "./Table-mayoristas.vue";
export default {
  name: "users",
  components: {
    tableMayo,
  },
  data() {
    return {
      headers: [
        { text: "Correo", value: "email" },
        { text: "Contraseña", value: "clave" },
        { text: "Rol", value: "rolDescription" },
        { text: "Nombre", value: "name", align:"left" },
        { text: "Activo/Desactivo", value: "active", align:"center" }
      ],
      content: [],
      tab: 0
    };
  },
  mounted() {
    this.chargeData();
  },
  methods: {
    chargeData: function () {
      this.$root.post("/wholesaler/GetUser").then(
        (response) => {
          if (response.data[0][0].success == "TRUE") {
            this.$root.token.token = response.data[0][0].token;
            this.$root.SetToken();
            this.content = response.data[1];
            this.$root.$children[0].listRol = response.data[2];
          } else {
            this.$root.swalAlert("error", "Error", response.data[0][0].message);
            this.$root.Exit();
          }
        }
      );
    },
  },
};
</script>

<style scoped>
</style>