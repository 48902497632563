<template>
  <v-card style="box-shadow: none !important">
      <v-card-title style="padding: 5px 25px 5px 26px;">
        <v-btn
          small
          color="success"
          @click="dialog = true"
        >
          <span class="material-icons" style="padding-right:5px;">
            visibility
          </span>
          Especificaciones de usuarios
        </v-btn>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label=""
          color="orange"
          placeholder="Buscar"
          prepend-icon="mdi-magnify"
          clearable
          single-line
          hide-details
          style="max-width: 400px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        class="styled-table m-4 mt-2"
        :sort-desc="[true, true]"
        :search="search"
        multi-sort
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="index" @click="edit(item, index)" style="color: black"
              :style="{ 'background-color': backgrondRowTable(index), 'border-top':'2px solid #75695f' }">
              <td>{{ item.email }}</td>
              <td>{{ item.clave }}</td>
              <td>{{ item.rolDescription }}</td>
              <td>
                {{ item.name + " " + item.name2 + " " + item.name3 }}
              </td>
              <td align="center">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      fab
                      elevation="1"
                      
                      class="success"
                      :color="item.active ? '' : '#f07f3a !important'"
                      @click="changeStatus(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span class="material-icons" v-if="item.active"> person </span>
                      <span class="material-icons" v-else> person </span>
                    </v-btn>
                  </template>
                  <span>{{item.active ? 'Desactivar Usuario': 'Activar Usuario'}}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar
              dark
            >
              <v-toolbar-title>Especificaciones de usuarios</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>          
          <v-card-text>
            <div class="container-user-description">
              <h2 class="text-center mt-3 title-user">Roles</h2>
              <p class="text-center subtitle-user">
                Los roles indican clases de usuarios que tienen asignados ciertos subconjuntos de tareas, ya sea por elección propia o como resultado de la organización en la que se encuentran
              </p>
              <v-simple-table
                class="w-100 my-6"
              >
                <tbody>
                  <tr style="background-color: rgba(255, 165, 0, 0.4)">
                    <td>
                      <v-simple-table>
                        <thead>
                          <tr style="background: rgb(253 142 48 / 22%); color: black">
                            <td class="MyTdPadding" align="center" style="font-size: 19px">
                              <strong>Funciones</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Cuenta Maestra </strong> 
                              <v-tooltip bottom max-width="350px">
                                <template v-slot:activator="{ on, attrs }">
                                  <sup v-bind="attrs" v-on="on"><v-icon small> mdi-help-circle-outline </v-icon></sup>
                                </template>
                                <span >
                                  Es la cuenta principal, para cada corporación solo puede existir una, esta es creada al momento del registro del mayorista. <br>
                                  Por eso no aparece para ser establecida en un rol.
                                </span>
                              </v-tooltip>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Administrador</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Coordinador</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Supervisor</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in contentTable" :key="`${index}`">
                            <td class="MyTdPadding">
                              {{item.title}}
                              <v-tooltip right max-width="350px">
                                <template v-slot:activator="{ on, attrs }">
                                  <sup><v-icon v-bind="attrs" v-on="on" small> mdi-help-circle-outline </v-icon></sup>
                                </template>
                                <span >
                                  {{item.description}}
                                </span>
                              </v-tooltip>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <v-icon :color="item.cuentaMaestra ? 'success' : 'warning'"> mdi-{{item.cuentaMaestra ? 'check-bold': 'close-thick'}} </v-icon>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <v-icon :color="item.administrador ? 'success' : 'warning'"> mdi-{{item.administrador ? 'check-bold': 'close-thick'}} </v-icon>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <v-icon :color="item.coordinador ? 'success' : 'warning'"> mdi-{{item.coordinador ? 'check-bold': 'close-thick'}} </v-icon>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <v-icon :color="item.supervisor ? 'success' : 'warning'"> mdi-{{item.supervisor ? 'check-bold': 'close-thick'}} </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";

export default {
  name: "tableMayo",
  props: {
    headers: Array,
    items: Array,
  },
  components: {},
  data() {
    return {
      backgrondRowTable,
      search: '',
      band: true,
      dialog: false,
      contentTable: [
        { 
          title: 'Administrar de usuarios',
          description: 'Crear, actualizar y desactivar usuarios pertenecientes al corporativo, así como brindarles roles.',
          cuentaMaestra: true, administrador: false, 
          coordinador: false, supervisor: false
        },
        { 
          title: 'Modificar logo corporativo',
          description: 'El logo de corporativo es la imagen representativa de él y sus miembros.',
          cuentaMaestra: true, administrador: false, 
          coordinador: false, supervisor: false
        },
        { 
          title: 'Administrar corporativo',
          description: 'Administración de la cuenta maestra, datos empresariales y accesos.',
          cuentaMaestra: true, administrador: false, 
          coordinador: false, supervisor: false
        },
        { 
          title: 'Visualizar catálogo',
          description: 'El catálogo es la variedad de boletos que el sistema ofrece, distribuido entre los distintos sitios.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: true
        },
        { 
          title: 'Administrar carrito',
          description: 'En el carrito se almacenan las futuras órdenes de pago, es decir los boletos que la empresa desea adquirir.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: false
        },
        { 
          title: 'Generar orden de pago',
          description: 'Es el proceso de adquirir los elementos del carrito.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: false
        },
        { 
          title: 'Visualizar órdenes de pago',
          description: 'Las órdenes de pago es el proceso de vinculación de la compra de boletos del corporativo y la entrega de los mismo brindada por los administrativos de Aafy.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: true
        },
        { 
          title: 'Visualizar estado de las órdenes de pago',
          description: 'Las órdenes de pago cuenta con diferentes estados los cuales son generadas, enviadas, autorizadas, bloqueadas.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: true
        },
        { 
          title: 'Imprimir órdenes de pago',
          description: 'Se imprime la orden de pago para proceder al depósito bancario.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: false
        },
        { 
          title: 'Capturar línea de referencia',
          description: 'Es la línea de referencia de la orden de pago. Al capturar la línea de referencia se hace el envió de la orden de pago a los administrativos de Aafy.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: false
        },
        { 
          title: 'Visualizar boletos disponibles',
          description: 'Boletos disponibles es suma total de boletos en órdenes de pago autorizadas.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: true
        },
        { 
          title: 'Generar boletos',
          description: 'Proceso de poner los boletos disponibles en lista de impresión, para su proximo próximo.',
          cuentaMaestra: true, administrador: true, 
          coordinador: false, supervisor: false
        },
        { 
          title: 'Visualizar boletos generados',
          description: 'Lista de impresión de boletos, es el módulo del sistema donde podremos imprimir boletos.',
          cuentaMaestra: true, administrador: true, 
          coordinador: true, supervisor: true
        },
        { 
          title: 'Imprimir boletos generados',
          description: 'Impresión de los boletos generados.',
          cuentaMaestra: true, administrador: true, 
          coordinador: false, supervisor: false
        },
      ]
    };
  },
  mounted(){
    setColorHeadFooter();
  },
  methods: {
    changeStatus: function (item) {
      this.band = false;
      item.active = item.active ? 0 : 1;
      this.$root
        .post("/wholesaler/SaveUser", item)
        .then((response) => {
          if (response.data[0][0].success == "TRUE") {
            this.$root.token.token = response.data[0][0].token;
            this.$root.swalAlert("success", "Usuarios", response.data[0][0].message);
            this.$parent.$parent.$parent.$parent.chargeData();
            this.$root.SetToken();
          } else {
            this.$root.swalAlert("error", "Error", response.data[0][0].message);
          }
        });
    },
    edit: function (item, i) {
      if (this.band) {
        this.$parent.$parent.$parent.$parent.$parent.dialog = true;
        var user = Object.assign({}, item);
        setTimeout(() => {
          this.$parent.$parent.$parent.$parent.$parent.$children[2].$children[0].$children[0].$children[0].editUser(
            user
          );
        }, 100);
      } else {
        this.band = true;
      }
    },
  },
};
</script>

<style scoped>
  .title-user{
    color: #444;
  }
  .subtitle-user{
    margin: 0px auto;
    max-width: 700px;
  }
  .container-user-description{
    max-width: 1000px;
    margin: 0px auto;
  }
</style>