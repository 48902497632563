<template>
  <div id="tickets" style="margin-top: 8px; max-height: 94vh" ref="tickets23">
    <v-tabs v-model="tab" dark>
      <v-tabs-slider></v-tabs-slider>

      <v-tab id="tab1" key="tab-1">
        <v-icon class="TabIconSpace ma-2" left> mdi-ticket-confirmation-outline </v-icon>
        Generar boletos
      </v-tab>

      <v-tab id="tab2" key="tab-2">
        <v-icon class="TabIconSpace ma-2" left> mdi-cloud-print-outline </v-icon>
        boletos generados
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="tab-1">
        <GenerateTickets :detSitios="detSitios"/>
      </v-tab-item>

      <v-tab-item key="tab-2">
        <TicketsGenerated :detTicketsGenerated="TicketsGenerated"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import GenerateTickets from './GenerateTickets.vue'
import TicketsGenerated from './TicketsGenerated.vue'
export default {
  name: "tickets",
  components: {
    GenerateTickets,
    TicketsGenerated
  },
  props: ["detSitios", "TicketsGenerated"],
  data(){
    return{
      tab: 0,
    }
  },
  watch: {
  },
  methods:{
    resolveGenerateTickets: function () {
      this.tab = 1;
      this.$children[1].$children[0].$children[0].reset();
    },
  }
};
</script>

<style scoped>

</style>