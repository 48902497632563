<template>
  <div style="padding:20px;">
    <div align="right">
      <div v-if="totalx">
        <strong
          ><h4>$ {{ formatPrice(totalx) }} MXN</h4>
        </strong>
      </div>
      <div v-if="rol < 3">
        <v-btn depressed color="warning" dark @click="Shoping" v-if="totalx" >
          Generar Orden de Pago
        </v-btn>
      </div>

      <v-alert icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" v-if="!totalx">
        No hay boletos agregados al carrito! 
      </v-alert>
    </div>

    <v-simple-table
      v-for="(item2, index2) in items"
      :key="'SubReportPayment2-' + index2"
    >
      <thead>
        <tr>
          <td
            style="background: white; color: black; padding: 0 !important; padding-top: 20px !important; padding-left: 5px !important; height: 40px; font-size:15px;"
          >
            <strong
              ><h5>{{ item2.name }}</h5></strong
            >
          </td>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: rgba(255, 165, 0, 0.4);">
          <td>
            <v-simple-table style="zoom: 90%">
              <thead>
                <tr style="background: rgb(253 142 48 / 22%); color: black">
                  <td style="width:40%;">
                    <strong>Nombre del Boleto</strong>
                  </td>
                  <td align="center" style="width:10%">
                    <strong>Fecha Limite de uso</strong>
                  </td>
                  <td align="center" style="width:10%">
                    <strong>Cantidad</strong>
                  </td>
                  <td align="right" style="width:20%;">
                    <strong>Precio Unitario</strong>
                  </td>
                  <td align="right" style="width:20%;">
                    <strong>Precio total</strong>
                  </td>
                  <td align="center" style="width:20%;" v-if="rol < 3">
                    <strong>Eliminar</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item3, index3) in item2.place"
                  :key="'SubReportPayment2-' + +index2 + '-' + index3"
                >
                  <td>
                    {{ item3.nombre }} <span v-if="item3.alias">({{ item3.alias }})</span>
                  </td>
                  <td align="center" style="color:red;">
                    <strong>{{item3.Ffin}}</strong>
                  </td>
                  <td align="center" class="CardFormat">
                    <div class="CardInput" v-if="rol < 3">
                      <div class="Lebox">
                        <div>
                          <v-btn
                            class="Lebtn LeRigth"
                            outlined
                            color="indigo"
                            @click="lessinput(item3)"
                          >
                            <span class="material-icons">
                              remove
                            </span>
                          </v-btn>
                        </div>
                        <div>
                          <input
                            v-model="item3.cantidad"
                            @keypress="isNumber($event)"
                            @keyup="changeinput(item3)"
                            class="MyInputNumber"
                            maxlength="8"
                          />
                        </div>
                        <div>
                          <v-btn
                            class="Lebtn LeLeft"
                            outlined
                            color="indigo"
                            @click="addinput(item3)"
                          >
                            <span class="material-icons">
                              add
                            </span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <strong v-else>{{item3.cantidad}}</strong>
                  </td>
                  <td align="right">
                    <strong>${{ formatPrice(item3.costo) }} MXN</strong>
                  </td>
                  <td align="right">
                    <strong
                      >${{
                        formatPrice(item3.costo * item3.cantidad)
                      }}
                      MXN</strong
                    >
                  </td>
                  <td align="right" v-if="rol < 3">
                    <v-tooltip right color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          class="ma-2"
                          @click="eliminar(item3)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="material-icons" style="font-size: 30px">
                            delete
                          </span>
                        </v-btn>
                      </template>
                      <span>Eliminar del carrito</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/NumericTools.js";

export default {
  name: 'TablePreCarrito',
  data() {
    return {
      formatPrice,
      search: "",
      flag: false,
      rol: 0,
      selected: [],
      TmpSelected: [],
      flagSelect: false,
      expanded: "",
    };
  },
  props: ["totalx", "items", "ShowCheck", "tab"],
  mounted() {
    this.rol = this.$root.token.subrol
  },
  methods: {
    Shoping() {
      var car = [];
      var ttoalX = 0;

      for (var x = 0; x < this.items.length; x++) {
        var s = this.items[x];
        for (var y = 0; y < s.place.length; y++) {
          let v = s.place[y];
          for (var z = 0; z < v.cantidad; z++) {
            ttoalX = ttoalX + v.costo;
            //lo que envia para el bulk
            car.push([v.id_ticket, 0, v.costo, s.sitio, v.persontype, v.npool])
          }
        }
      }
      let vv = { id: null, total: ttoalX, estatus: 1, data: car }

      this.$root.post("/Shoping", vv).then((response) => {

        var idx = response.data[0]
        this.$root.swalAlert('success', 'Orden de compra realizado con exito', null)
        this.$parent.$parent.$parent.$parent.tab = 1

        setTimeout(() => {
          if(idx.id){
            this.$parent.$parent.$parent.$parent.$refs.TableBita1.clickrow(idx.id)
          }
        }, 10)
        
        let vx = {token:this.$root.token.token, coorId:this.$root.token.idCorp}
        //this.$parent.$parent.$parent.$parent.$parent.socketX('OrderOAy', vx)
        this.$parent.$parent.$parent.$parent.$parent.$parent.socketX('OrderOAy', vx)

      });
    },
    eliminar(ticket){
      this.$swal({
        title: "¿Esta seguro?",
        text: "¡Eliminar del carrito!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "primary",
        confirmButtonText: "Si",
        cancelButtonColor: "#dd8159",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$root
          .post("/wholesaler/RemoveOfPreCarrito", { id_ticket: ticket.id_ticket, persontype: ticket.persontype ,  idCorp: this.$root.token.idCorp })
          .then((response) => {
            if(response.data[0].success == 'TRUE'){
              this.$parent.$parent.$parent.$parent.iniPreCarrito()
            }
          });
        }
      });
    },
    changeinput(value) {
      if (value.cantidad == "") {
        value.cantidad = 0;
      }
      value.cantidad = parseInt(value.cantidad)
    },
    addinput(item) {
      item.cantidad = parseInt(item.cantidad) + 5;
      this.recalcular()
    },
    lessinput(item) {
      if (parseInt(item.cantidad) - 5 < 0) {
        item.cantidad = 0;
      } else {
        item.cantidad = parseInt(item.cantidad) - 5;
      }
      this.recalcular()
    },
    recalcular(){
      setTimeout(() => {
        var total = 0
        for(var x=0; x < this.items.length; x++){
          let val1 = this.items[x]
          for(var y=0; y < val1.place.length; y++){
            let val2 = val1.place[y]
            total = total + (val2.costo * val2.cantidad)
          }
        }
        this.$parent.$parent.$parent.$parent.$parent.totalx = total
        this.$parent.$parent.$parent.$parent.totalx = total
        
      }, 10)
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        this.recalcular()
        return true;
      }
    },
  },
};
</script>

<style scoped>
.Lebtn {
  width: 30px !important;
  min-width: 30px !important;
  margin: 0 !important;
  height: 35px !important;
  background: #ffffff;
}
.Lebtn:hover {
  background: orange !important;
}
.Lebtn:hover span {
  color: white !important;
}
.Lebtn span {
  font-size: 22px;
  margin-left: -2px;
  font-weight: bold;
}
.LeRigth {
  border-radius: 5px 0px 0px 5px !important;
  border-right: none !important;
}
.LeLeft {
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}
.Lebox {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-wrap: nowrap;
}
.CardFormat .CardInput {
  padding: 0px;
  min-width: 200px;
}
.CardFormat .MyInputNumber {
  font-size: 18px;
  background: white;
  padding: 3px;
  border: 1px solid black;
  width: 100%;
  text-align: right;
}
.CardFormat .CardMoney {
  font-size: 18px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
}
.CardFormat .CardTite2 {
  font-size: 20px;
  line-height: 1.4rem !important;
  padding-bottom: 0;
  font-weight: bold;
}
.CardFormat .CardTitel {
  font-size: 15px;
  line-height: 1.4rem !important;
  padding-bottom: 0;
  padding: 10px;
}
.CardFormat {
  height: 100%;
}
</style>
