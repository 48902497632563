<template>
  <div>
    <div class="file-upload" v-if="!isAdduserr">
      <p class="subtitle">Imagen Corporativa</p>
      <v-row>
        <v-col cols="12" sm="6">
          <input id="FileOpem" type="file" @change="onFileChange" accept="image/png, image/jpeg" style="display:none;"/>
          <v-btn dark color="green" @click="OpenFile()">
            Cargar Imagen
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" align="center">
          <img :src="urlX" style="max-width: 200px; max-height: 200px;">
          <br>
          <v-btn dark color="blue" @click="onUploadFile" class="upload-button"
            :disabled="!this.selectedFile">Guardar Imagen Corporativa</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-divider v-if="!isAdduserr"></v-divider>
  
  <v-form ref="form" lazy-validation id="Form-mayoristas">

    <p class="subtitle">Datos de Usuario</p>
    <v-row justify-sm="center">
        <v-col cols="12" sm="6">
          <v-text-field
            append-icon="email"
            name="Correo Personal"
            label="Correo Personal"
            type="email"
            outlined
            v-model="formUser.email"
            :error="error"
            :rules="[rules.required, rules.email]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :type="hidePassword ? 'password' : 'text'"
            :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
            name="Contrasena"
            label="Contraseña"
            outlined
            @click:append="hidePassword = !hidePassword"
            v-model="formUser.clave"
            :error="error"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            append-icon="person"
            name="Nombre"
            label="Nombre"
            type="text"
            outlined
            v-model="formUser.name"
            :error="error"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            append-icon="person"
            name="ApellidoPaterno"
            label="Apellido Paterno"
            type="text"
            outlined
            v-model="formUser.name2"
            :error="error"
            :rules="[]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            append-icon="person"
            name="ApellidoMaterno"
            label="Apellido Materno"
            type="text"
            outlined
            v-model="formUser.name3"
            :error="error"
            :rules="[]"
          />
        </v-col>
        <v-col v-if="isAdduserr" cols="12" sm="6">
          <v-select
            v-model="formUser.rol"
            :items="roles"
            label="Rol"
            outlined
            item-text="name"
            item-value="subrol"
            :rules="[rules.required]" 
          >
          </v-select>
        </v-col>
      </v-row>

    <p v-if="!isAdduserr" class="subtitle">Datos de la Empresa</p>
    <div v-if="!isAdduserr">
      <v-row>
        <v-col cols="12">
          <v-text-field
            append-icon="badge"
            name="RFC"
            label="RFC"
            type="text"
            outlined
            v-model="formCoor.companyRFC"
            @keyup="rfcParseUppercase()"
            :error="error"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            append-icon="business"
            name="CompanyName"
            label="Nombre"
            type="text"
            outlined
            v-model="formCoor.companyName"
            :error="error"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            append-icon="location_on"
            name="Direccion"
            label="Dirección"
            type="text"
            outlined
            v-model="formCoor.companyAddress"
            :error="error"
            :rules="[]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            append-icon="phone"
            name="Telefono"
            label="Telefono"
            type="text"
            outlined
            v-model="formCoor.companyPhone"
            :error="error"
            :rules="[]"
          />
        </v-col>
      </v-row>
    </div>

    <v-row class="my-2 row-butons-form-mayo" align="center">
      <v-btn @click="cancelEditUser" v-if="isAdduserr">Cancelar</v-btn>
      <v-btn @click="clearForm" v-else>Regresar</v-btn>
      <v-btn class="primary" color="#df8157 !important" @click="submit">
        Guardar
      </v-btn>
    </v-row>
  </v-form>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";

export default {
  name: "FormMayoristas",
  props: {
    isAdduserr: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlX:'',
      blockref:false,
      error: false,
      selectedFile: "",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      },
      roles: [],
      formUser: {
        active: 1,
        clave: "",
        email: "",
        id: "",
        name: "",
        name2: "",
        name3: "",
        rol: 0,
      },
      formCoor: {
        companyRFC: "",
        companyName: "",
        companyAddress: "",
        companyPhone: "",
      },
      hidePassword: true,
    };
  },
  mounted() {
    if (!this.isAdduserr) {
      this.editUserMayo();
    } else {
      this.roles = this.$root.$children[0].listRol;
    }
  },
  methods: {
    OpenFile(){
      $("#FileOpem").click()
    },
    onFileChange(e) {                
      if(e.target.files[0].size > 1048576 ){
        this.$root.swalAlert('error','Mayor de 1 MB',null)
        return false
      }

      const selectedFile = e.target.files[0]; // accessing file
      this.urlX = URL.createObjectURL(selectedFile)
      this.selectedFile = selectedFile;

    },
    onUploadFile() {
      this.blockref = true
      let pat           = this.selectedFile.name.split('.')
      pat               = this.$root.token.idCorp + '.' + pat[1]
      const formData    = new FormData()
      formData.append("file", this.selectedFile)
      formData.set("name2", pat)
      axios
        .post(this.$root.myserver + this.$root.myport+"/upload", formData, { headers: { "X-Access-Token": pat }})
        .then(res => {

          this.$root.token.avatar             = res.data.path
          this.$parent.$parent.$parent.avatar = res.data.path
          this.$root.swalAlert('success','Imagen modificada',null)
          return false
        })
        .catch(err => {
          return false
        });
        return false
    },
    clearForm: function () {
      this.formUser = {
        id: "",
        email: "",
        clave: "",
        name: "",
        name2: "",
        name3: "",
        rol: 0,
        active: 1,
      };
      this.formCoor = {
        companyRFC: "",
        companyName: "",
        companyAddress: "",
        companyPhone: "",
      };
      this.hidePassword = true;
      this.$refs.form.resetValidation();
      if (!this.isAdduserr) {
        this.$parent.$parent.$parent.openView("Catalogo")
      }
    },
    submit: function () {
      if(this.blockref == true){
        this.blockref = false
      }

      if (this.$refs.form.validate()) {
        if (this.isAdduserr) {
          this.$root
            .post("/wholesaler/SaveUser", this.formUser)
            .then((response) => {
              if (response.data[0][0].success == "TRUE") {
                this.$root.token.token = response.data[0][0].token;
                this.$root.SetToken();
                this.$root.swalAlert("success", "Usuarios", response.data[0][0].message);
                this.$parent.$parent.$parent.$parent.$children[3].chargeData();
                this.$parent.$parent.$parent.$parent.dialog = false;
                this.clearForm();
              } else {
                this.$root.token.token = response.data[0][0].token;
                this.$root.SetToken();
                this.$root.swalAlert("error", "Error", response.data[0][0].message);
              }
            });
        } else{
          var data = Object.assign({}, this.formUser, this.formCoor);
          this.$root
          .post("/wholesaler/SaveUser", data)
          .then((response) => {
            if (response.data[0][0].success == "TRUE") {
              this.$root.swalAlert("success", "Usuarios", response.data[0][0].message);
              this.$parent.$parent.$parent.refreshToken(response.data[1][0])
            } else {
              this.$root.token.token = response.data[0][0].token;
              this.$root.SetToken();
              this.$root.swalAlert("error", "Error", response.data[0][0].message);
            }
          });
        }
      }
    },
    editUser: function (user) {
      Object.assign(this.formUser, user);
    },
    cancelEditUser: function () {
      this.$parent.$parent.$parent.$parent.dialog = false;
      this.clearForm();
    },
    editUserMayo: function () {
      this.$root
      .post("/wholesaler/GetUserCorp", {id: this.$root.token.id})
      .then((response) => {
        if (response.data) {
          Object.assign(this.formUser, response.data[0][0]);
          Object.assign(this.formCoor, response.data[1][0]);
          this.urlX = this.$root.token.avatar;
        } else{
          this.$parent.$parent.$parent.openView("Catalogo")
        }
      });
    },
    rfcParseUppercase: function () {
      this.formCoor.companyRFC = this.formCoor.companyRFC.toUpperCase()
    }
  },
};
</script>

<style scoped>
.col.col-12,
.col-sm-6.col-12,
.col-sm-8.col-12 {
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}

.btn-custome-orange {
  background-color: red;
}

.row-butons-form-mayo {
  justify-content: center;
  gap: 7vw;
}
</style>