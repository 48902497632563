<template>
  <div>
    <v-card class="w-100 pa-5">
      <v-row>
        <v-col cols="12" align="center">
          <h3><strong>Boletos disponibles</strong></h3>
          <h4>
            Maximo de
            <span class="red--text text--lighten-1">
              100
            </span>
            boletos por transaccion

          </h4>
        </v-col>
        <v-col cols="12" md="9" style="padding-top:0; padding-bottom:0;"  v-if="rol < 2">
          <h5>Boletos utilizados: {{CantBoletos}}</h5>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="rol < 2"
          align="right"
          style="padding-top:0; padding-bottom:0;"
        >
          <v-btn color="success" @click="alertPrint" block v-if="cantidades.length">
            <v-icon left> mdi-ticket-confirmation-outline </v-icon>
            Generar boletos
          </v-btn>
        </v-col>
      </v-row>
      <br>

      <div class="mt-3" style="max-height: 63vh; overflow: auto">
        <v-simple-table
          class="w-100"
          v-for="(sitio, index) in detSitios"
          :key="'sitios-' + index"
        >
          <thead>
            <tr>
              <td
                style="
                  background: white;
                  color: black;
                  padding: 0 !important;
                  padding-left: 5px !important;
                  height: 40px;
                  font-size: 15px;
                "
              >
                <strong
                  ><h5>{{ sitio.name }}</h5></strong
                >
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color: rgba(255, 165, 0, 0.4)">
              <td>
                <v-simple-table>
                  <thead>
                    <tr style="background: rgb(253 142 48 / 22%); color: black">
                      <td class="MyTdPadding" align="center" width="15%">
                        <strong>Tipo de boleto</strong>
                      </td>
                      <td class="MyTdPadding" align="left" width="30%">
                        <strong>Boleto</strong>
                      </td>
                      <td
                        class="MyTdPadding"
                        align="center"
                        style="min-width: 170px;"
                         width="20%"
                      >
                        <strong>Fecha Limite de uso</strong>
                      </td>
                      <td class="MyTdPadding" align="center" width="15%">
                        <strong>Disponibles(s)</strong>
                      </td>
                      <td
                        class="MyTdPadding"
                        align="center"
                        style="min-width: 213px"
                        v-if="rol < 2"
                        width="20%"
                      >
                        <strong>Utilizar</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sitioTicket, index1) in sitio.data"
                      :key="
                        `sitioTickets-${sitioTicket.id_ticket}-${sitioTicket.persontype}-${sitioTicket.npool}`
                      "
                    >
                      <td class="MyTdPadding" align="center">
                        <strong>{{ sitioTicket.id_ticket }}</strong>
                      </td>
                      <td class="MyTdPadding">
                        <strong>{{ sitioTicket.descripcion }} </strong>  
                        <span v-if="sitioTicket.alias"> ({{ sitioTicket.alias }})</span>
                      </td>
                      <td class="MyTdPadding" align="center" style="color:red;">
                        <strong>{{ sitioTicket.Ffin }}</strong>
                      </td>
                      <td class="MyTdPadding" align="center">
                        <strong>{{ sitioTicket.max }}</strong>
                      </td>
                      <td class="MyTdPadding" align="center"  v-if="rol < 2">
                        <div class="Lebox">
                          <div>
                            <v-btn
                              class="Lebtn LeLeft"
                              outlined
                              color="indigo"
                              @click="addinput(index, index1)"
                            >
                              <span class="material-icons"> add </span>
                            </v-btn>
                          </div>
                          <div>
                            <input
                              v-model="detSitios[index].data[index1].cantidad"
                              :max="sitioTicket.cantidad"
                              @keypress="isNumber($event)"
                              @keyup="changeinput(index, index1)"
                              class="MyInputNumber"
                            />
                          </div>
                          <div>
                            <v-btn
                              class="Lebtn LeRigth"
                              outlined
                              color="indigo"
                              @click="lessinput(index, index1)"
                            >
                              <span class="material-icons"> remove </span>
                            </v-btn>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>


    <v-dialog v-model="dialog" max-width="700px">
      <v-card class="pb-2">
        <v-card-title class="text-h5 grey lighten-2">
          Detalles de impresión
        </v-card-title>

        <v-card-text>
          <label></label>
          <v-text-field
            label="Escriba una descipcion de la transaccion"
            :hint="'Maximo ' + maxx + ' caracteres'"
            v-model="descript"
            :counter="maxx"
          ></v-text-field>
          <v-simple-table
            style="zoom: 90%"
            v-for="(item, index2) in ticketsSelected"
            :key="'selectedSitio-' + index2"
          >
            <thead>
              <tr>
                <td
                  style="
                    background: white;
                    color: black;
                    padding: 0 !important;
                    padding-top: 20px !important;
                    padding-left: 5px !important;
                    height: 40px;
                    font-size: 15px;
                  "
                >
                  <strong
                    ><h5>{{ item.nameSitio }}</h5></strong
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: rgba(255, 165, 0, 0.4)">
                <td>
                  <v-simple-table>
                    <thead>
                      <tr
                        style="background: rgb(253 142 48 / 22%); color: black"
                      >
                        <td style="width: 70%">
                          <strong>Nombre del Boleto</strong>
                        </td>
                        <td align="center" style="width: 10%">
                          <strong>Cantidad</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item2, index2) in item.data"
                        :key="'seletedItemSitio-' + index2"
                      >
                        <td>{{ item2.name }}</td>
                        <td align="center">{{ item2.cantidad }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <p class="text-center mb-1">
          <strong>¿Seguro de continuar con esta accion?</strong>
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="print" :loading="loading2" :disabled="loading2"> 
            Aceptar 
            <template v-slot:loader>
              <span>Generando...</span>
            </template>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="'#dd8159'"
            style="color: white !important"
            :disabled="loading2"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { sumarDias } from "@/utils/ReportsTools.js";

export default {
  name: "GenerateTickets",
  data() {
    return {
      loading2: false,
      maxx: 250,
      CantBoletos:0,
      descript: "",
      menu2: false,
      banner: true,
      date: "",
      nowDate: new Date().toISOString().slice(0, 10),
      ticketsSelected: [],
      cantidades: [],
      dialog: false,
      rol: 0
    };
  },
  props: ["detSitios"],
  watch: {
    date: function() {
      if (this.date == this.nowDate) {
        this.banner = true;
      } else {
        this.banner = false;
      }
    },
    dialog: function() {
      if (!this.dialog) {
        this.ticketsSelected = [];
      }
    },
  },
  mounted() {
    this.date = sumarDias(new Date(Date.now()), +1);
    this.chargeData();
    this.rol = this.$root.token.subrol
  },
  methods: {
    chargeData: function() {
      //this.$parent.$parent.$parent.$parent.socketX(
      this.$parent.$parent.$parent.$parent.$parent.socketX(
        "reloadData",
        this.$root.token.token
      );
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return false;
      }
    },
    addinput: function(index, index1) {
      var cant = this.detSitios[index].data[index1].cantidad;
      var max = this.detSitios[index].data[index1].max;

      if (cant < max) {
        this.detSitios[index].data[index1].cantidad =
          this.detSitios[index].data[index1].cantidad + 1;
      }
      this.revisa();
    },
    lessinput: function(index, index1) {
      var cant = this.detSitios[index].data[index1].cantidad;
      if (cant > 0) {
        this.detSitios[index].data[index1].cantidad =
          this.detSitios[index].data[index1].cantidad - 1;
      }
      this.revisa();
    },
    changeinput: function(index, index1) {
      var cant = this.detSitios[index].data[index1].cantidad;
      var max = this.detSitios[index].data[index1].max;
      if (cant == "") {
        this.detSitios[index].data[index1].cantidad = 0;
      } else if (cant > max) {
        this.detSitios[index].data[index1].cantidad = max;
      } else if (cant < 0) {
        this.detSitios[index].data[index1].cantidad = 0;
      } else {
        this.detSitios[index].data[index1].cantidad = parseInt(cant);
      }
      this.revisa();
    },
    revisa: function() {
      this.cantidades = [];
      this.CantBoletos = 0;
      this.detSitios.forEach((element) => {
        element.data.forEach((item) => {
          if (item.cantidad != 0) {
            this.CantBoletos = this.CantBoletos + item.cantidad
            this.cantidades.push({
              id: item.id_ticket,
              name: item.descripcion,
              sitio: item.sitio,
              cantidad: item.cantidad,
              nameSitio: element.name,
              persontype: item.persontype,
              npool: item.npool,
            });
          }
        });
      });
    },
    alertPrint: function() {
      if(this.CantBoletos >= 101){
        this.$root.swalAlert('warning', 'Excedio el numero maximo de boletos', null)
      }else{
        this.descript = "";
        this.dialog = true;
        this.loading2 = false;
        this.cargeticketsSelected();
      }
    },
    print: function() {
      this.loading2 = true
      //this.$parent.$parent.$parent.$parent.socketX("printTickets", {
      this.$parent.$parent.$parent.$parent.$parent.socketX("printTickets", {
        user: this.$root.token.id,
        idCorp: this.$root.token.idCorp,
        dateUse: this.date,
        isNowDate: this.date == this.nowDate ? 1 : 0,
        data: this.cantidades,
        token: this.$root.token.token,
        descript: this.descript,
      })
      this.dialog = false;
      
      /* this.$parent.$parent.$parent.$parent.ShowLoading = true
      this.$parent.$parent.$parent.$parent.textLoading = 'Activando Boletos un momento por favor..' */
      this.$parent.$parent.$parent.$parent.$parent.ShowLoading = true
      this.$parent.$parent.$parent.$parent.$parent.textLoading = 'Activando Boletos un momento por favor..'
    },
    cargeticketsSelected: function() {
      var sitiosID = [];
      this.cantidades.forEach((element) => {
        if (!sitiosID.includes(element.sitio)) {
          sitiosID.push(element.sitio);
          var datos = [];
          this.cantidades.forEach((item) => {
            if (element.sitio == item.sitio) {
              datos.push(item);
            }
          });
          datos.sort(function(a, b) {
            if (a.descripcion > b.descripcion) return 1;
            if (a.descripcion < b.descripcion) return -1;
            return 0;
          });
          this.ticketsSelected.push({
            id: element.sitio,
            nameSitio: element.nameSitio,
            data: datos,
          });
        }
      });
      this.ticketsSelected.sort(function(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    reset: function () {
      this.CantBoletos = 0;
      this.cantidades = [];
      this.ticketsSelected = [];
    }
  },
};
</script>

<style scoped>
.MyForm {
  height: 75px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}
.Lebtn {
  width: 30px !important;
  min-width: 30px !important;
  margin: 0 !important;
  height: 35px !important;
  background: #ffffff;
}
.Lebtn:hover {
  background: orange !important;
}
.Lebtn:hover span {
  color: white !important;
}
.Lebtn span {
  font-size: 22px;
  margin-left: -2px;
  font-weight: bold;
}
.LeRigth {
  border-radius: 5px 0px 0px 5px !important;
  border-right: none !important;
}
.LeLeft {
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}
.Lebox {
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
}
.MyInputNumber {
  font-size: 18px;
  background: white;
  padding: 3px;
  border: 1px solid #777;
  width: 100%;
  text-align: right;
  height: 35px !important;
}
</style>
