<template>
  <div>
    <v-card class="w-100 pb-6">
      <v-card-title style="padding: 5px 25px 5px 26px;">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label=""
          color="orange"
          placeholder="Buscar"
          prepend-icon="mdi-magnify"
          clearable
          single-line
          hide-details
          style="max-width: 400px"
        ></v-text-field>
      </v-card-title>
      <div>
        <TablaBitacora2 :headers="headers" :items="detTicketsGenerated" :search="search"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import TablaBitacora2 from "./TablaBitacora2.vue"

export default {
  name: "TicketsGenerateds",
  props: ["detTicketsGenerated"],
  components: {
    TablaBitacora2
  },
  data() {
    return {
      dialog: false,
      search: '',
      headers: [
        { text: '', value: 'icon'},
        { text: "Clave de transaccion", align: "start", value: "clave", },
        { text: "Fecha de solicitud", value: "f_solicitud" },
        { text: "Cantidad", value: "cantidad", align:"right" },
        { text: "Nombre de Solitante", value: "name", align:"center" },
        { text: "Descripcion", value: "descript", align:"center" },
      ]
    };
  },
  watch: {
    
  },
  mounted() {
    this.chargeData();
  },
  methods: {
    chargeData: function() {
      //this.$parent.$parent.$parent.$parent.socketX(
      this.$parent.$parent.$parent.$parent.$parent.socketX(
        "GetTicketsGenerated",
        this.$root.token.token
      );
    }
  },
};
</script>
